.phoneInput{
    height: 46px;
    margin-top: 8px;
}


.phoneInput .ant-input-wrapper{
    height: 46px;
}
.phoneInput .ant-input-wrapper .ant-input-group-addon{
    padding: 0px 18px;
}

.phoneInput .ant-input-wrapper input{
    height: 46px;
}